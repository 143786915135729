import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import translations from '@shopify/polaris/locales/en.json';
import 'animate.css';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return <AppProvider i18n={translations}>{element}</AppProvider>;
};
